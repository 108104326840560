/* all css styles have  */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: #d9dcd6;
}

.associate {
  float: left;
  width: 20%;
  height: auto;
  min-width: 150px;
  /* width:  150px;
  height: 150px; */
  /* object-fit: cover; */
  margin: 15px;
}

/* .associateHome {
  width:  150px;
  height: 150px;
  text-align: center;
  margin: 5px auto
} */
.center {
  float: none;
  margin: 5px auto;
  text-align: center;
}

/* removed . as this is now a semantic tag in index */
header {
  padding: 20px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  background-color: #2a607c;
  color: #ffffff;
}

.jumbotron {
  background-color: #2a607c;
  padding: 0;
  margin: 0 0;
  text-align: center;
}

h1 {
  font-size: 7vw
}

h2 {
  font-size: 3.5vw
}

h4 {
  font-size: 3vw
}

button {
  margin: 10px;
}

/* I thought the image should go in HTML as its content, but apparently not true for background images */
.hero {
  overflow: auto;
  width: 100%;
  height: 300px;
  min-height: 100px;
  margin-bottom: 25px;
  background-image: url("./images/Building.jpg");
  background-size: cover;
  background-position: center;
}

#sidebar {
  background-color: #0072bb;
  padding-top: -1px;
}

a {
  color: white
}

.linkOnPage {
  color: darkblue
}

.blueLink {
  color: darkblue
}
.hours {
  margin-bottom: 32px;
  margin-left: 25px;
  color: #ffffff;
}

#mobileInfo {
  display: none;
}

.hours h3 {
  margin-bottom: 10px;
  text-align: left;
}


.location {
  margin-bottom: 32px;
  margin-left: 25px;
  color: #ffffff;
}

.location h3 {
  margin-bottom: 10px;
  text-align: left;
}
.container-md {
  margin: 0px;
}
.video-container {
  overflow: hidden;
  position: relative;
  width:100%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.procedures {
  margin-bottom: 32px;
  margin-left: 25px;
  color: #ffffff;
}

.procedures h3 {
  margin-bottom: 10px;
  text-align: left;
}



footer {
  padding: 30px;
  clear: both;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  text-align: center;
}

footer h2 {
  font-size: 20px;
}

.card-bio {
  background-color: #0072bb;
  color: #ffffff;
  margin: 15px;
  margin-top: 0px;
  padding: 20px;
}

@media screen and (max-width: 600px) {
  #sidebar {
    display: none
  }
  #mobileInfo {
    display: inline-block;
  }
  .hero {
    height: 100px;
  }
}